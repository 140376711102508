import { template as template_d1990dbc61464c1d8990cece48332b99 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d1990dbc61464c1d8990cece48332b99(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
