import { template as template_4d6a8d36675a47b58212f6893d8ac5e2 } from "@ember/template-compiler";
const WelcomeHeader = template_4d6a8d36675a47b58212f6893d8ac5e2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
